.dashboard {
  &-elem {
    background-color: #F0F4F6;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    .header {
      h2 {
        color: #3B4C60;
        font-size: 20px;
        margin-bottom: 20px;

        .font-icon {
          font-size: 16px;
          margin-right: 7px;
          vertical-align: text-top;
        }

        .dashboard-link {
          color: inherit !important;
        }
      }

      .actions {
        text-align: right;

        .ant-btn {
          color: #587999;
          background-color: #E3EDF4;
          font-size: 12px;
          border: none;
          margin-left: 5px;

          .font-icon {
            margin-left: 5px;
          }
        }
      }
    }

    .content {
      .custom-agreements-elem {
        border-radius: 20px;
        background-color: #D5D5F3;
        font-size: 12px;
        margin-bottom: 10px;

        .label {
          color: #A7A7FA;
        }

        .value {
          font-weight: bold;
        }

        &-top {
          margin-bottom: 20px;
          .value {
            font-size: 20px;
          }
        }

        &-left {
          height: 100%;
          padding: 20px;
          border-radius: 20px;
          background-color: @mainBlueColor;
          overflow: hidden;

          .label {
            color: rgba(255, 255, 255, 0.5);
          }

          .value {
            color: #fff;
          }
        }

        &-right {
          padding: 20px;

          .value {
            color: #5353F4;
          }
        }

        .showMoreButton {
          text-align: center;
          padding: 20px;
          font-size: 20px;

          .font-icon {
            cursor: pointer;

            &:before {
              transform: rotate(90deg);
              color: #5353F4;
            }

            &.rotate:before {
              transform: rotate(270deg);
              color: #A7A7FA;   
            }
          }
        }

        &-bottom-attachments {
          margin-top: 20px;

          h2 {
            font-weight: bold;
            font-size: 24px;
            color: @mainBlueColor;
          }
          
          .attachment {
            background-color: transparent;
            border: 1px solid #A7A7FA;
            line-height: 30px;
            width: auto;
            margin: 10px;
            color: @mainBlueColor;
            font-weight: bold;

            &-icon .font-icon {
              color: @mainBlueColor;
              font-size: 15px;
            }

            &-actions .ant-btn {
              color: @mainBlueColor;
            }
          }
        }
      }
      
      &-elem {
        color: #fff;
        font-size: 12px;
        background-color: transparent;
        border-radius: 20px;
        margin-bottom: 20px;

        &-top {
          background-color: transparent;
          padding: 20px;

          border-radius: 20px;
          &-higher {
            font-size: 34px;
            font-weight: bold;
          }
        }

        &-bottom {
          padding: 20px;
          &-lower {
            font-weight: bold;
          }
        }

        &.dashboard-elem-red {
          background-color: #EA7E84;
          .content-elem-top {
            background-color: #ED565E;
          }
          .content-elem-bottom {
            color: #B1494E;
          }
        }

        &.dashboard-elem-blue {
          background-color: #B1CADE;
          .content-elem-top {
            background-color: #8FB3D1;
          }
          .content-elem-bottom {
            color: #587999;
          }
        }

        &.dashboard-elem-darkblue {
          background-color: #587999;
          .content-elem-top {
            background-color: #3B4C60;
          }
          .content-elem-bottom {
            color: #3B4C60;
          }
        }

        &.dashboard-elem-grey {
          color: #587999;
          background-color: #E3EDF4;
        }

        &.dashboard-elem-navy {
          background-color: #7777F6;
          .content-elem-top {
            background-color: @mainBlueColor;
          }
          .content-elem-bottom {
            color: #5151BA;
          }
        }

        &.dashboard-elem-green {
          background-color: #56E089;
          .content-elem-top {
            background-color: @mainGreenColor;
          }
          .content-elem-bottom {
            color: #14AD4E;
          }
        }

        &.dashboard-elem-empty {
          border: 2px solid #E3EDF4;
          .content-elem-top {
            color: #587999;
            border: 2px solid #C0D6E5;
          }
          .content-elem-bottom {
            color: #B1CADE;
          }
        }

        &.hide {
          border: 2px solid #E3EDF4;
          background-color: transparent;
          opacity: .5;
          .content-elem-top {
            color: #587999;
            border: none;
            background-color: transparent;
          }
          .content-elem-bottom {
            visibility: hidden;
          }
        }

        &.dashboard-link {
          transition: all 0.2s ease-out;

          &:hover {
            opacity: 0.9;
          }
        }
        &.custom-premises-dashboard-stats {
          background-color: #587999;
          .content-elem-top {
            background-color: #587999;
          }
          .content-elem-bottom {
            color: #C0D6E5;
          }
          &.dashboard-elem-darkblue {
            background-color: #3B4C60;
            .content-elem-top {
              background-color: #3B4C60;
            }
          }

          .custom-premises-dashboard-stats-premises-top {
            font-size: 24px;
            font-weight: bold;
          }
          .custom-premises-dashboard-stats-premises-bottom {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      &-elem-full {
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 10px;

        &-left {
          &-bottom {
            font-weight: bold;
            font-size: 24px;
          }
        }

        &-middle {
          &-bottom {
            font-weight: bold;
            font-size: 24px;
          }
        }

        &-right {
          &-bottom {
            font-size: 12px;
          }
        }

        &-href {
          margin-top: 18px;

          .font-icon:before {
            transform: rotate(180deg);
          }
        }


        &.dashboard-elem-full-grey {
          background-color: #ECECF7;
          color: #5353F4;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #A7A7FA;
            }
          }

          .content-elem-full-href a{
            color: #A7A7FA !important;
          }
        }
        
        &.dashboard-elem-full-black {
          background-color: #3B4C60;
          color: #fff;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #587999;
            }
          }

          .content-elem-full-href a{
            color: #587999 !important;
          }
        }

        &.dashboard-elem-full-navy {
          background-color: @mainBlueColor;
          color: #fff;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #A7A7FA;
            }
          }

          .content-elem-full-href a{
            color: #A7A7FA !important;
          }
        }
      }
    }
  }
}