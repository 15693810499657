.datagrid-columns-popover-wrapper {
  .ant-popover-title {
    color: #B1CADE;
  }

  .ant-form-item-control-input {
    min-height: 28px;

    span {
      color: #587999;
    }
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.datagrid-filter-modal-button-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  
  button {
    max-width: 200px;
    width: 100%;
  }
}

.datagrid-wrapper {
  padding: 16px 24px;
  
  .datagrid-link {
    color: @mainBlackColor !important;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 24px;
  }

  .datagrid-actions-wrapper {
    button {
      margin-left: 5px;
      i {
        padding-left: 5px;
      }
    }
  }

  .datagrid-columns-buttons {
    span {
      color: #fff;
    }
  }

  .ant-table {
    font-size: 12px;
    overflow-x: auto;
  }

  .ant-table-thead {
    th {
      background-color: #fff;
      white-space: nowrap;
      &.ant-table-column-has-sorters:hover,
      &.ant-table-column-sort.ant-table-column-has-sorters:hover {
        background-color: #F0F4F6;
      }
    }
  }
  .ant-table-tbody {
    td {
      background-color: #fff;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .ant-btn:hover {
        background-color: #E3EDF4 !important;
        color: #3B4C60 !important;
      }
    }
    tr.ant-table-row:hover > td,
    tr.ant-table-row-selected > td {
      background-color: #F0F4F6;

      .ant-btn {
        background-color: #F0F4F6;
      }
    }

    tr.row-error-red > td {
      background-color: rgba(255, 0, 0, 0.1);

      .ant-btn {
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 0, 0, 0.1) !important;
        }
      }
    }

    tr.ant-table-row.row-error-red:hover > td,
    tr.ant-table-row-selected.row-error-red > td {
      background-color: rgba(255, 0, 0, 0.2);

      .ant-btn {
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 0, 0, 0.2) !important;
        }
      }
    }

    .ant-table-selection-column {
      width: 50px;
    }
  }

  .expanded-datagrid-row {

    .datagrid-wrapper {
      padding: 0;
    }
  
    & > td {
      padding: 0;
    }

    .ant-table-content table, td, td:hover, .ant-table-summary, .ant-table-summary td, .ant-table-thead th {
      background-color: #F0F4F6;
    }

    .ant-table-tbody td {
      border-radius: 0;

      .datagrid-row-actions .ant-btn {
        background-color: #F0F4F6;
      }
    }

    .ant-table-summary td:first-child {
      border-top-left-radius: 0;
    }

    .ant-table-thead th {
      font-weight: bold;
    }
  }
}

.datagrid-columns-buttons-ok span {
  color: #fff !important;
}

.datagrid-columns-buttons-refresh {
  background-color: rgba(236, 67, 76, 0.1);
  border: none;
  color: rgba(236, 67, 76, 1);
}