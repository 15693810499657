@media only screen and (max-width: 576px) {
  .datagrid-wrapper .datagrid-actions-wrapper, .ant-page-header-heading-extra {
    .ant-btn {
      padding: 10px;
      line-height: 1;
      width: 36px;
      height: 36px;

      & > span {
        display: none;
      }
      & > i {
        padding-left: 0;
      }
    }
  }

  .premises-stats-elem-value {
    font-size: 25px !important;
  }

  .form-create .ant-tabs-tab:hover, .form-create .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: red;
  }

  #czater-contener {
    display: none !important;
  }

  .create-form-password-card {
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
  }
  .form-create .ant-tabs-nav-list {
    margin-left: 20px;
  }

  .form-create, .show-layout {
    .ant-page-header-back .ant-page-header-back-button {
      padding: 10px !important;
    }
  }

  .ant-page-header-heading-left {
    width: calc(100% - 50px);
  }

  .page-header-extra-2 .ant-page-header-heading-left {
    width: calc(100% - 100px);
  }

  .gallery-wrapper-add {
    max-width: initial;
    max-height: initial;
  }

  .premises-show-box.costs-box .content .value {
    font-size: 30px;
  }

  .investments-list-item-drag {
    display: none !important;
  }
}

@media only screen and (min-width: 576px) {
  .form-create .ant-tabs-nav-list {
    width: 274px;
  }

  .ant-tabs-tab-active {
    background-color: #F0F4F6;
  }

  .all-investments-stats-button .font-icon{
    padding-left: 5px;
  }

  .non-mobile-align-right {
    text-align: right;
  }

  .premises-stats-elem:before {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .premises-stats-elem:before {
    width: 75%;
  }
}

@media only screen and (min-width: 992px) {
  .premises-stats-elem:before {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  .premises-stats-elem:before {
    width: 85%;
  }
}

@media only screen and (min-width: 1600px) {
  .premises-stats-elem:before {
    width: 90%;
  }
}