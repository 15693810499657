.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &.green {
    background-color: @mainGreenColor;
  }

  &.red {
    background-color: #EC434C;
  }
  
  &.grey {
    background-color: #C0D5E4;
  }

  &.yellow {
    background-color: #F9DF45;
  }

  &.blue {
    background-color: #587999;
  }

  &.halfred {
    background: linear-gradient(to right, #EC434C 50%, white 50%);
    border: 2px solid #EC434C;
  }
}

.ant-select,
.ant-select-item {
  .dot {
    margin-right: 5px;
    vertical-align: -2px;
  }
}