.ant-form-item {
  margin-bottom: 10px;
  box-shadow: none !important;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-disabled+label {
    background: linear-gradient(
      0deg
      , #f5f5f5 50%, #fff 50%);
  }

  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input-number,
  .ant-select,
  .ant-input-number-input,
  .ant-input-number-input,
  .ant-input {
    border-color: #C0D6E5;
  
    &+label {
      color: #C0D6E5;
    }

    .ant-select-selector {
      border-color: #C0D6E5 !important;
      box-shadow: none !important;
    }
  
    &:hover {
      border-color: #8FB3D1;
      
      .ant-select-selector {
        border-color: #8FB3D1;
      }
      z-index: 0;
    }
  
    &:focus, &-focused {
      border-color: #587999;
      box-shadow: none !important;
      z-index: 0;
      .ant-select-selector {
        border-color: #587999 !important;
        box-shadow: none !important;
      }
    }
  
    &:focus+label,
    &-focused+label {
      color: #587999;
    }

    &-disabled {
      background-color: #fff;
      &+label {
        color: #82939e;
      }
    }
  }

  &-has-error .ant-input,
  &-has-error .ant-select,
  &-has-error .ant-select:hover,
  &-has-error .ant-select:focus,
  &-has-error .ant-picker,
  &-has-error .ant-picker:hover,
  &-has-error .ant-picker:focus,
  &-has-error .ant-input-number,
  &-has-error .ant-input-number:hover,
  &-has-error .ant-input-number:focus,
  &-has-error .ant-input-affix-wrapper,
  &-has-error .ant-input:hover,
  &-has-error .ant-input-affix-wrapper:hover,
  &-has-error .ant-input:focus,
  &-has-error .ant-input-affix-wrapper:focus {
    border-color: #ff4d4f !important;

    &+label {
      color: #ff4d4f !important;
    }
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    background-color: #F0F4F6;
    height: 25px;
    line-height: 25px;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 10px 10px 0px 11px;
}
