.smalllist-wrapper {

  .smalllist-link {
    color: @mainBlackColor !important;

    &:hover {
      text-decoration: underline;
    }
  }
  .smalllist-inherit-link {
    color: inherit !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-table {
    font-size: 12px;
    overflow-x: auto;
    background-color: unset;
  }
  
  table {
    border-collapse: separate; 
    border-spacing: 0 10px;
  }
  .ant-table-thead {
    th {
      white-space: nowrap;
      background-color: #fff;
      color: #B1CADE;
      border-bottom: none;
    }
  }
  .ant-table-tbody {

    td {
      background-color: #F0F4F6;
      border-bottom: none;
      

      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    tr.ant-table-row:hover > td,
    tr.ant-table-row-selected > td {
      background-color: #F0F4F6;
    }
    
    tr.ant-table-placeholder > td {
      background: #fff;
    }
  }
  .ant-table-summary {
    td {
      border-bottom: none;
    }
  }
  &-row {
    &-action {
      &.ant-btn {
        background: transparent !important;
        color: #C0D5E4;
  
        &:hover,
        &:active {
          color: rgba(0, 0, 0, 0.85)
        }
        &::after {
          box-shadow: none;
          border: none;
        }
      }
  
      .icon-more-vertical::before {
        transform: rotate(90deg);
      }
    }

    &-icon {
      color: #C0D5E4;
      font-size: 25px;
    }
  }
}