.premises {
  &-gallery-slider {
    margin-bottom: 40px;
    background-color: #F0F4F6;
    border-radius: 20px;

    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      color: unset;
      font-size: 30px;
      z-index: 20;
      padding: 30px;
      width: auto;
      height: auto;
    }

    .ant-carousel .slick-prev {
      left: 20px;
    }
    .ant-carousel .slick-next {
      right: 20px;
    }

    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
      color: unset;
    }

    &-slide-wrapper {
      img {
        max-height: 350px;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
    
  }
  &-edit {
    .attachments,
    .agreements,
    .brokers,
    .faults,
    .tenantChanges {
      .smalllist-wrapper-row-icon {
        font-size: 16px;
      }
    }
  }

  &-stats {
    margin-top: 20px;
    
    &-elem {
      border-radius: 20px;
      padding: 25px;
      color: #fff;
      position: relative;
      height: 100%;
      display: block;

      &:before {
        width: 90%;
        height: 10px;
        display: block;
        content: ' ';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin-left: auto; 
        margin-right: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      &-value {
        font-size: 3vw;
        font-weight: bold;
      }

      &:hover {
        opacity: 0.9;
      }

      &-active {
        opacity: 0.5;
      }
    }

    &-SALE {
      .premises-stats-elem {
        background-color: @mainGreenColor;

        &:before {
          background-color: darken(@mainGreenColor, 5%);
        }
      }
    }

    &-RENT {
      .premises-stats-elem {
        background-color: @mainBlueColor;

        &:before {
          background-color: darken(@mainBlueColor, 5%);
        }
      }
    }

    &-STATS {
      .premises-stats-elem {
        background-color: #ECECF7;
        color: #3B4C60;

        &:before {
          background-color: #D5D5F3;
        }

        &-value {
          color: @mainBlueColor;
        }

        &.simulation {
          background-color: #E3EDF4;
          color: #587999;
          
          &:before {
            background-color: #D6E1E9;
          }
        }
      }

      .simulation {
        .premises-stats-elem-value {
          font-size: 2vw;
        }
      }
    }
  }

  &-show {
    &-header {
      h1 {
        font-size: 28px;

        .dot {
          margin-left: 10px;
        }
      }

      &-price {
        font-size: 28px;
        text-align: right;

        span.sell {
          text-decoration: line-through;
        }
      }

      &-address {
        color: #B1CADE;
        font-size: 14px;
      }
    }
    &-box {
      border: 1px solid #ECECF7;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 20px;
      position: relative;

      &:before {
        width: 90%;
        height: 10px;
        display: block;
        content: ' ';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin-left: auto; 
        margin-right: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      h2 {
        color: @mainBlueColor;
        margin-bottom: 20px;
        font-weight: bold;

        .font-icon {
          margin-right: 10px;
        }
      }

      &.agreements-box {
        background-color: @mainBlueColor;
        color: #ffffff;

        &:before {
          background-color: darken(@mainBlueColor, 5%);
        }

        h2 {
          color: #ffffff;
        }
      }

      &.price-box,
      &.costs-box {
        background-color: @mainGreenColor;
        color: #ffffff;

        &:before {
          background-color: darken(@mainGreenColor, 5%);
        }
        
        h2 {
          visibility: hidden;
        }

        .content {
          .title {
            font-size: 14px;
          }

          .value {
            font-size: 24px;
          }
        }
      }

      &.costs-box {
        .content {
          .title {
            font-size: 14px;
          }

          .value {
            font-size: 54px;
            font-weight: bold;
          }
        }
      }

      &.room-box {
        .room-box-costs {
          margin-bottom: 20px;
          font-size: 16px;

          .value {
            color: @mainBlueColor;
            font-weight: bold;
          }
        }
        
        .rooms-list-room {
          background: transparent;
          border: 1px solid #F0F4F6;

          &-name {
            margin-top: 15px;
          }

          &-size {
            margin-bottom: 15px;
          }

          &-costs {

            &-rent,
            &-charges {
              font-weight: bold;
              color: @mainBlueColor;
            }

            &-deposit {
              color: #8FB3D1;
            }
          }
        }
      }

      &.info-box {
        .content {
          .label {
            color: #A7A7FA;
          }
        }
      }

      &.attachments-box {
        .attachment {
          line-height: 30px;
          background: transparent;
          border: 1px solid #ECECF7;

          &-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .ant-btn {
            background: transparent !important;
            color: #C0D5E4;
      
            &:hover,
            &:active {
              color: rgba(0, 0, 0, 0.85)
            }
            &::after {
              box-shadow: none;
              border: none;
            }
          }
        }
      }

      

      .smalllist-wrapper {

        .ant-table-tbody td {
          background: transparent;
          border-top: 1px solid #ECECF7;
          border-bottom: 1px solid #ECECF7;
          padding: 12px;

    
          &:first-child {
            border-left: 1px solid #ECECF7;
          }
    
          &:last-child {
            border-right: 1px solid #ECECF7;
          }
        }
      }

      &.brokers-box {
        .ant-table-tbody td {
          background: #ECECF7;
          border-top: 1px solid #ECECF7;
          border-bottom: 1px solid #ECECF7;

    
          &:first-child {
            border-left: 1px solid #ECECF7;
          }
    
          &:last-child {
            color: @mainBlueColor;
            font-weight: bold;
            border-right: 1px solid #ECECF7;
          }

          .premises-broker-phone {
            color: #A7A7FA;
          }

          .icon-percent {
            font-size: 13px;
          }
        }
      }

      &.tranches-box {
        .ant-table-tbody td:first-child {
          padding: 12px 12px 12px 17px;
        }
      }

      &.agreements-box {
        .single-agreement {
          border: 1px solid #6565F7;
          border-radius: 20px;
          padding: 20px;
          margin-bottom: 20px;

          &-name {
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 20px;
          }

          .label {
            color: #A7A7FA;
          }

          &-clients {
            margin-bottom: 20px;
            margin-top: 20px;
          }

          &-header-info {
            text-align: right;

            .ant-btn {
              background-color: #ECECF7;
              color: @mainBlueColor;
              font-size: 12px;
              font-weight: bold;
            }
          }

          h2 {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .attachment {
            line-height: 30px;
            background: transparent;
            border: 1px solid #7777F6;
  
            &-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &-actions {
              text-align: right;
              .ant-btn {
                background: transparent !important;
                color: #C0D5E4;
          
                &:hover,
                &:active {
                  color: rgba(0, 0, 0, 0.85)
                }
                &::after {
                  box-shadow: none;
                  border: none;
                }
              }
            }
  
            
          }

          .ant-table-content {
            background-color: @mainBlueColor;
            color: #fff;
  
            .ant-table-tbody td,
            .ant-table-tbody tr:hover td {
              background: #7777F6;
              border-top: 1px solid #7777F6;
              border-bottom: 1px solid #7777F6;
    
        
              &:first-child {
                border-left: 1px solid #7777F6;
              }
        
              &:last-child {
                border-right: 1px solid #7777F6;
              }
    
              .icon-tenant {
                font-size: 15px;
                color: #fff;
              }
            }

            
          }
        }
        
      }
    }
  }
}

.premise-investment-address {
  background: #F0F4F6;
  padding: 20px 30px;
  border-radius: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  .ant-col {
    align-self: center;
  }
}

.paid-tranche {
  padding: 20px 30px;
  text-align: center;

  &-header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }
  &-info {
    margin-bottom: 15px;
    color: #B1CADE;
  }

  &-buttons .ant-btn {
    width: 100%;
  }
}
