.single-package {
  text-align: center;
  font-size: 14px;
  padding: 30px;
  margin-bottom: 20px;

  &-header {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-investments {
    color: #252D38;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &-price {
    font-weight: bold;
    font-size: 54px;
  }
  &-addnotation {
    font-size: 12px;
    color: #8FB3D1;
    margin-bottom: 30px;
  }

  &-limits {
    text-align: left;
    width: 80%;
    margin: 0 auto 30px;

    &-single-value {
      font-weight: bold;
    }

    &-addnotation {
      font-size: 10px;
      color: #8FB3D1;
    }
  }

  &-action {
    button {
      width: 160px;
      height: 48px;
    }

    button[disabled]{
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
      border-color: #f5f5f5 !important;
    }
  }
}

.header-package-button {
  background-color: #E3EDF4 !important;

  .icon-arrow-simply-right {
    margin-left: 5px;
    
    &::before {
      transform: rotate(180deg);
    }
  }
}

.packages-help {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;

  a {
    color: @mainBlueColor;
    font-weight: bold;

    .icon-arrow-simply-right {
      margin-left: 5px;
      
      &::before {
        transform: rotate(180deg);
      }
    }
  }
}

.account-edit-packages {
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
  color: #fff;

  @media only screen and (min-width: 576px) {
    margin: 30px;
    padding: 50px;
  }


  &-header {
    font-size: 34px;
    font-weight: 600;

    &-price {
      font-size: 24px;

      &-addnotation {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 576px) {
        text-align: right;
      }
    }
  }

  &-details {
    &-investments {
      color: #252D38;
      font-size: 16px;
      margin-bottom: 20px;
    }
  
    &-limits {
      text-align: left;
  
      &-single-value,
      &-single-investments-value,
      &-single-investments-label {
        font-weight: bold;
      }

      &-single-investments-label,
      &-single-investments-value {
        margin-bottom: 10px;;
      }
  
      &-addnotation {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &-actions {
      margin-top: 10px;

      button {
        margin-top: 10px;
      }

      @media only screen and (min-width: 576px) {
        margin-top: 0;
        text-align: right;

        button {
          margin-left: 10px;
          margin-top: 0;
        }
      }
    }
  }

  

  // &:before {
  //   width: 95%;
  //   height: 10px;
  //   display: block;
  //   content: ' ';
  //   position: absolute;
  //   top: -10px;
  //   left: 0;
  //   right: 0;
  //   margin-left: auto; 
  //   margin-right: auto;
  //   border-top-left-radius: 20px;
  //   border-top-right-radius: 20px;
  //   // background-color: inherit;
  // }
}