.tpay-settings {
  background-color: #072653;
  border-radius: 20px;
  padding: 50px;
  margin: 30px;
  
  h2 {
    color: #15D55E;
    font-weight: bold;
  }

  .ant-form-item {
    .ant-input {
      background-color: #072653;
      color: #587999;
    }

    label {
      background-color: #072653;
    }
  }

  .button-wrapper {
    .ant-btn {
      width: 100%;
    }
  }

  .image-wrapper {
    text-align: right;
  }

  
}

.methods {
  padding: 20px;
  
  @media only screen and (min-width: 576px) {
    padding: 100px;
  }

  .method {
    padding: 10px;
    border: 1px solid #ccc;
    height: 90px;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border: 2px solid #ccc;
    }

    img {
      max-width: 100%;
      width: auto;
    }
  }
}