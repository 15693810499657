.investments {
  &-form {
    &-header {
      &-title {
        &-name {
          color: @mainBlueColor;
        }
      }
    }
  }

  &-show {
    &-left {
      .logo {
        img {
          max-width: 300px;
        }
      }
    }
    &-right {
      .informations {
        background-color: #fff !important;
      }

      &-actions {
        &-action {
          width: 100%;
          height: 175px;
          color: @mainBlueColor !important;
          display: block;
          padding: 30px;
          margin-top: 20px;
          border: 1px solid #ECECF7;
          border-radius: 20px;
          transition: background-color 0.3s ease-in-out;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;

          i {
            margin-top: 20px;
            display: block;
          }
          &:hover {
            background-color: #ECECF7;
          }
        }
      }
    }
  }

  &-list {
    &-item {
      max-width: 275px;
      margin: 0 auto 30px;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 5px 10px -5px #ccc;
      transition: box-shadow 0.3s ease-in-out;
      position: relative;
  
      &:hover {
        box-shadow: 0px 5px 10px 0px #ccc;

        .investments-list-item-drag {
          display: block;
        }
      }
  
      &-logo {
        height: 200px;
  
        img {
          max-height: 200px;
          margin: auto;
          max-width: 100%;
          display: block;
        }
      }

      &-drag {
        background: #fff;
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        font-size: 20px;
        padding: 6px 13px;
      }
  
      &-menu {
        padding: 20px;
        color: rgba(0, 0, 0, 0.85);
  
        &-name {
          font-size: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.85) !important;
        }

        &-type {
          font-size: 10px;
          color: #B1CADE;
        }

        button {
          color: rgba(0, 0, 0, 0.85) !important;

          .icon-more-vertical::before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

