@import '~antd/dist/antd.less';
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import './styles/investments.less';
@import './styles/rooms.less';
@import './styles/smalllist.less';
@import './styles/premises.less';
@import './styles/attachment.less';
@import './styles/gallery.less';
@import './styles/tenantChanges.less';
@import './styles/print.less';
@import './styles/faults.less';
@import './styles/notifications.less';
@import './styles/dashboard.less';
@import './styles/stats.less';
@import './styles/datagrid.less';
@import './styles/dot.less';
@import './styles/tpay.less';
@import './styles/packages.less';
@import './styles/inputs.less';
@import './styles/tags.less';
@import './styles/demo.less';
@import './styles/themes.less';
@import './styles/mobile.less';

@mainGreenColor: #15D55E;
@mainBlueColor: #5353F4;
@mainBlackColor: #3B4C60;

body {
  font-family: 'Overpass', sans-serif;
  overflow-y: auto !important;
}

.loading {
  text-align: center;
  padding: 20% 50px;
  margin: 20px 0 20px;
}

[ant-click-animating-without-extra-node]:after {
  animation: 0s !important;
}
.ant-btn {
  box-shadow: none !important;
  text-shadow: none !important;
}
.button-no-border {
  border: none;
}
//icons
[class*='icon-']:before{
  vertical-align: middle;
  margin-bottom: 3px;
}
.icon-arrow-short-right::before {
  transform: rotate(180deg);
}

.icon-arrow-simply-down::before {
  transform: rotate(270deg);
}

.icon-arrow-simply-down.rotate-180::before {
  transform: rotate(90deg);
}

.ant-btn.ant-btn-background-ghost::after {
  border: none !important;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.login-form-wrapper, .reset-password-form-wrapper {
  max-width: 300px;
  margin: 30% auto 30%;
  .logo {
    margin: 50px auto;
    display: block;
  }
}

.ant-layout-sider {
  background-color: #252D38;
  background-image: url('./static/images/menuBackground.png');
  background-position: top left;
  background-repeat: no-repeat;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 100vh;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 5;

  .menu-header {
    .logo {
      height: 155px;
      display: block;
      position: relative;
  
      .border-wrapper {
        position: absolute;
        top: 32px;
        left: 32px;
        width: 108px;
        height: 108px;
        display: block;
        background-color: @mainGreenColor;
        border-radius: 50%;
      }
      .image-wrapper {
        display: block;
        margin: 5px;
        background-size: cover;
        background-position: center center;
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }
    }
    
    .text {
      margin: 0 20px 20px;
      color: #fff;
      font-size: 14px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .address {
        margin-top: 14px;
        font-size: 12px;
        color: #587999;
      }

    }
    
  }
  

  .ant-menu {
    background: transparent;
    font-weight: bold;
    font-size: 14px;

    .ant-menu-item-selected {
      background-color: rgba(255,255,255,0.05) !important;
    }

    .font-icon {
      margin-right: 10px;
    }
  }

  .help {
    margin-top: 30px;
    color: #587999;

    span a {
      color: #587999;
    }
  }
}

.login-form .login-form-button,
.reset-password-form .reset-password-form-button {
  width: 100%;
}
.login-form-forgot {
  text-decoration: underline;
}
.aside {
  .logo {
    padding-left: 30px;
    padding-top: 30px;
  }
  .carousel {
    padding-top: 20%;
    padding-bottom: 50px;

    .slick-dots {
      bottom: -40px;
      

      li {
        width: 9px;
        button {
          height: 9px;
          border-radius: 4px;
        }

        &.slick-active {
          width: 36px;
          button {
            background: @mainGreenColor;
          }
        }
      }
    }
  }
  .slide {
    color: #8FB3D1;
    
    h1 {
      color: @mainGreenColor;
      font-weight: bold;
      font-size: 46px;
      margin-left: 60px;
      line-height: 58px;
      margin-right: 15px;
    }

    p {
      margin-left: 60px;
      margin-right: 15px;
      font-size: 24px;
      line-height: 31px;
    }
  }
}

.ant-layout {
  background-color: #F0F4F6;

  .site-layout {
    background-color: #fff;
  
    .ant-layout-header {
      background-color: #F0F4F6;
    }
  }
}

.delete-confirm-modal {
  text-align: center;

  .ant-modal-confirm-title {
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-confirm-btns {
    float: none;

    button {
      width: 115px;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #587999;
}
.ant-checkbox-inner {
  border-radius: 4px;
  background-color: #fff;
  border-color: #587999;
}
.ant-checkbox-checked::after {
  border-color: transparent;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #587999;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 4px;
  background-color: #587999;
  border-color: #587999;
  &::after{
    color: #fff;
  }
}

.show-layout {
  .left {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;

    .logo {
      img {
        width: 100%;
      }
    }

    .name {
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .position {
      margin-bottom: 20px;
      font-size: 16px;
      color: #C5D6E3;
    }

    .buttons {
      .call {
        margin-bottom: 10px;
      }

      .write {
        button {
          background-color: @mainBlueColor;
          border-color: @mainBlueColor;
        }
      }

      button {
        width: 100%;

        i {
          padding-right: 5px;
        }
      }
    }
  }
  .right {
    .ant-card {
      border: 1px solid #ECECF7;
      margin-bottom: 10px;
      margin-top: 10px;
      
      &-head {
        border-bottom: none;
        padding-right: 60px;

        h2 {
          font-size: 18px;
          font-weight: bold;
          color: @mainBlueColor;
          margin-bottom: 0;

          i {
            margin-right: 10px;
            font-size: 25px;
          }
        }
      }

      &.access .ant-card-body {
        h2 {
          color: @mainBlueColor;
          font-size: 16px;
        }

        .value {
          font-weight: bold;
        }
      }

      .ant-card-body {
        padding-left: 60px;
        padding-right: 60px;

        .title {
          color: @mainBlueColor;
        }
      }
    }
  }
}

.form-create {
  .form-create-main-card > .ant-card-body {
      padding: 0;
  }

  .create-form-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .ant-tabs-nav-list {
    margin-top: 20px;
    font-size: 16px;
  }
  
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @mainBlueColor;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .form-section {
    margin-bottom: 20px;
  }

  .create-form-password-card {
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px solid @mainBlueColor;

    .ant-card-body {
      padding-left: 0;
      padding-right: 0;
    }
    .create-form-button-send-password {
      width: 100%;
      color: #fff;
      background-color: @mainBlueColor;
      i {
        padding-left: 10px;
      }
    }

    .send-password-image-wrapper {
      text-align: right;

      img {
        max-width: 300px;
        width: 100%;
      }
    }
  }
}

.button-secondary {
  color: @mainBlueColor;
  background-color: #ECECF7;
  border-color: #ECECF7;
  font-weight: bold;

  &:focus,
  &:hover,
  &:active {
    background-color: #D5D5F3;
    border-color: #D5D5F3;
    color: @mainBlueColor;
  }

  &.active {
    background-color: @mainBlueColor;
    border-color: @mainBlueColor;
    color: #ECECF7;
  }
}

.dropdown-menu-wrapper {
  i {
    margin-right: 5px;
  }
}
.ant-page-header-back {
  
  background-color: #ECECF7;
  border-radius: 5px;
  transition: all 0.3s;

  &:hover {
    background-color: #D5D5F3;
  }

  .ant-page-header-back-button {
    padding: 16px !important;
    

    i {
      color: @mainBlueColor;
    }
  }
  
}
.ant-page-header-heading-title {
  font-weight: normal;
  font-size: 24px;

  span.header-subtitle {
    color: @mainBlueColor;
  }
}

.ant-page-header-heading-extra {
  button {
    i {
      padding-left: 5px;
    }
  }
}

.userIcon-wrapper {
  .userIcon-circle {
    max-width: 300px;
    width: 100%;
    margin: 50px auto;
  }
}

.ant-layout-sider-collapsed {
  .menu-header {
    display: none;
  }
  .ant-menu-item {
    display: none;
  }
}
.site-layout-header {
  padding: 0;
  .trigger {
    cursor: pointer;
    margin-left: 20px;
    color: @mainBlueColor;
  }

  .site-layout-header-actions {
    float: right;
    margin-right: 10px;

    button {
      background-color: transparent;

      i {
        color: #C0D6E5;
      }

      &:hover {
        i {
          opacity: .8;
        }
      }
    }
  }
}

.form-actions {
  button {
    max-width: 160px;
    width: 100%;

    i {
      padding-right: 5px;
    }
  }
  &-save-wrapper {
    text-align: right;
  }
}

.upload-avatar-wrapper {
  position: relative;
  img {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 300px;
  }

  .change-button {
    position: absolute;
    bottom: 0;
    right: 20px;
    opacity: 0;
    transition: 0.5s all ease-in-out;

    i {
      padding-left: 5px;
    }
  }

  &:hover {
    .change-button {
      opacity: 1;
    }
  }
}


.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
  width: 100%;
  height: 250px;
}

.premises-custom-create-link {
  color: rgba(0, 0, 0, 0.85) !important;
}

.button-group {
  button {
    border-radius: 0;
  }

  button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

}

.ant-picker-large {
  padding: 16px 12px 4px 11px;

  .ant-picker-suffix {
    line-height: 1.5715;
  }
}

.costs-box {
  background-color: @mainGreenColor;
  color: #ffffff;

  &:before {
    background-color: darken(@mainGreenColor, 5%);
  }
  
  h2 {
    visibility: hidden;
  }

  .content {
    .title {
      font-size: 14px;
    }

    .value {
      font-size: 24px;
    }
  }
}

.broker-icon {
  background-color: #F0F4F6;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: text-top;
}

.ant-modal-mask, .ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #B1CADE;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-confirm-body {
  .ant-modal-confirm-content {
    color: #B1CADE;
  }

  .ant-modal-confirm-title {
    color: #3B4C60;
  }
}

.ant-modal-confirm-btns {
  .ant-btn-dangerous {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
  }
}

.not-found {
  .error-wrapper {
    text-align: center;
    margin-top: 10%;
    color: #3B4C60;

    img {
      max-width: 100%;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }
  }
}

.ant-input-number-lg input {
  padding: 0;
  height: initial;
}

.ant-upload-list-picture-card-container+.ant-upload-select-picture-card {
  display: none;
}

.chat-button-wrapper {
  position: fixed;
  bottom: 100px;
  right: 20px;

  .ant-btn {
    padding: 0;
    overflow: hidden;
    height: auto;
    background: none;
    width: auto;
  }
}

#czater-contener {
  bottom: -0px !important;

  .czater-button {
    display: none !important;
  }
}

.ant-form-horizontal .permissions .ant-form-item-label {
  text-align: left;
  width: 200px;
}

.gallery > .ant-row > .ant-col-offset-2,
.create-form-wrapper > .ant-row > .ant-col-offset-2 {
  margin-top: 8.3333333%;
  margin-bottom: 8.3333333%;
}

.header-menu-wrapper {
  .premises-custom-create-link {
    color: rgb(88, 121, 153) !important;
  }
  .font-icon.icon-logout::before {
    transform: rotate(90deg);
  }
}


//layout fix
.site-layout {
  margin-left: 0px;
  transition: margin-left .2s;
}

.ant-layout-sider {
  transition: all .1s;
  z-index: 5;
  &:not(.ant-layout-sider-collapsed) + .site-layout {
    .site-layout-header {
      text-align: right;

      .trigger {
        margin-right: 20px;
      }
    }
    .site-layout-header-actions {
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) {
  .site-layout {
    margin-left: 236px;
  }

  .ant-layout-sider {
    z-index: 1;
    &.ant-layout-sider-collapsed + .site-layout {
      margin-left: 30px;
      .site-layout-header {
        text-align: left;
  
        .trigger {
          margin-right: 0;
        }
      }
      .site-layout-header-actions {
        display: block;
      }
    }

    &:not(.ant-layout-sider-collapsed) + .site-layout {
      .site-layout-header {
        text-align: left;
      }
      .site-layout-header-actions {
        display: block;
      }
    }
  }
}

.reset-password-form-back-wrapper i::before {
  vertical-align: top;
  margin-top: 4px;
}

.account-expired-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .account-expired-popup {
    width: 200px;
    margin: 20% auto 0;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    background: #fff;
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 15px 24px 8px 35px;
  position: relative;

  .font-icon {
    position: absolute;
    left: 14px;
    top: 14px;
    color: #3B4C60;
  }
}

.button-no-border:hover, .button-no-border:focus {
  color: #3B4C60;
  background: transparent;
}

.rodo-content {
  white-space: pre-wrap;
  max-width: 600px;
  width: 100%;
  margin: 30px auto;
  padding: 10px;
}
@primary-color: #15D55E;@border-radius-base: 10px;@heading-color: #3B4C60;@error-color: #EC434C;@text-color: #3B4C60;@text-color-secondary: #3B4C60;